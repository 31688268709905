<template>
  <div id="profile-page">
    <AppContent
      class="profile-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <b-alert
        v-if="user.activation_used == 0 && user.user_type != 1"
        variant="warning"
        show>
        <div>
          <span class="fas fa-exclamation-triangle"></span>
          Verify account to unlock other cool features!
          Didn't receive it yet?
          <a
            href="javascript:void(0);"
            @click="sendActivation">
            Resend verification email
          </a>
        </div>
      </b-alert>

      <Details @toggle-edit-form="showEditForm = !showEditForm" />
      
      <EditForm
        @cancel-edit="showEditForm = !showEditForm"
        @success-edit="showEditForm = !showEditForm"
        v-if="showEditForm"
      />

      <div v-if="user.activation_used === 1">
        <Contents class="mt-5 mb-5" v-if="user.user_type < 5" />

        <!-- <BroadcastSlots
          class="mt-3 mb-3"
          :influencer="user.user_id"
          :tz="user.timezone"
          v-if="user.user_type === 5"
        />
        <ClassroomList
          class="mt-3 mb-3"
          v-if="user.user_type === 3 || user.user_type === 4"
        /> -->
      </div>
      <br>
    </AppContent>

    <modals-container />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name : 'ProfilePage',
    head : {
      title : {
        inner      : 'Profile',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        showEditForm : false,
      }
    },
    computed : {
      ...mapGetters({
        'user' : 'user/user',
      }),
    },
    components : {
      'AppContent' : () => import('../../components/layout/AppContent'),
      'Details'    : () => import('./Details'),
      'EditForm'   : () => import('./EditForm'),
      'Contents'   : () => import('./Contents'),
      
      // 'BroadcastSlots' :
      //   () => import('../../components/user/profile/BroadcastSlots'),
      // 'ClassroomList' :
      //   () => import('../../components/user/profile/ClassroomList'),
    },
    methods : {

      /**
       * Send Verificaiton email
       */
      sendActivation() {
        this.$http.post('auth/sendActivationEmail', {
          to : this.user.email,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Activation email sent.',
          });
        });
      },
    },
    mounted() {
      this.$store.dispatch('subscription/getSubscription');
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #profile-page {
    min-height: 100vh;
    background-image: $profile-bg;
    background-attachment: scroll;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    .profile-content {
      min-height: calc(100vh - 5rem);
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .profile-content {
      min-height: calc(100vh - 8rem);
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .profile-content {
      min-height: calc(100vh - 10rem);
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #profile-page {
      padding-left: calc(#{$sidebar-width} + 2em);
    }
    .profile-content {
      min-height: calc(100vh - 3rem);
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    
  }

  @media only screen and (min-width: $special) {
    #profile-page {
      padding-left: $sidebar-width;
    }
    .profile-content {
      min-height: calc(100vh - 3rem);
    }
  }

  @media only screen and (min-width: $xl2-min) {
    #profile-page {
      padding-left: 8rem;
    }
    .profile-content {
      min-height: calc(100vh - 3rem);
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    #profile-page {
      padding-left: calc(#{$sidebar-width + 2em});
    }
    .profile-content {
      min-height: calc(100vh - 4rem);
    }
  }
</style>